import Vue from 'vue'
import 'vant/lib/index.less'

// 按名称顺序排序
import {
  Button,
  Checkbox,
  Dialog,
  Form,
  Field,
  Toast,
  Tab,
  Tabs,
  Popup,
  Icon
} from 'vant'

Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(Form)
Vue.use(Toast)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)
Vue.use(Icon)
