import Vue from 'vue'
import './utils/vant' // 有赞ui库
import { install } from './utils'
import router from './router'
// 全局样式
import "./assets/css/index.less"
import App from './App.vue'

Vue.use(install)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
