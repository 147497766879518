import Vue from 'vue'
import Router from 'vue-router'

import home from "./src/home"
Vue.use(Router)

const router = new Router({
  mode: "history",
  base: '/',
  // base: process.env.BASE_URL,
  routes: [
    ...home
  ],
  // 路由跳转返回顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
// 路由前置守卫
router.beforeEach(async (to, from, next) => {
  next()
})

export default router



