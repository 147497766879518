// 首页路由
import HomeLayout from '@/components/layout/HomeLayout'

export default [
  {
    path: '',
    redirect: "/",
    component: HomeLayout,
    children: [
      {
        path: "/",
        component: () => import(/* webpackChunkName: "Index" */ "@/views/index"),
        meta: {title: "电动自行车检"}
      },
      {
        path: "/login",
        component: () => import(/* webpackChunkName: "Login" */ "@/views/login"),
        meta: {title: "登录"}
      },
      {
        path: "/car",
        component: () => import(/* webpackChunkName: "Car" */ "@/views/car"),
        meta: {title: "上牌"}
      },
      {
        path: "/pay",
        component: () => import(/* webpackChunkName: "Pay" */ "@/views/pay"),
        meta: {title: "确认投保"}
      },
      {
        path: "/success",
        component: () => import(/* webpackChunkName: "success" */ "@/views/pay/success"),
        meta: {title: "激活成功"}
      }
    ]
  }
]
